let url = 'http://localhost:8080/api-7ZWBz/';

if (process.env.ASSISTANT_API_URL) {
  url = process.env.ASSISTANT_API_URL;
}

if (url.endsWith('/')) {
  url = url.substring(0, url.length - 1);
}

const urlObj = new URL(url);

export const apiUrl = url;
export const hostUrl = urlObj.origin;
export const tenantId = urlObj.pathname.replace('/api', '').replace('-', '');
export const defaultFetchOpts = {
  mode: 'cors',
  headers: {
    'X-Requested-By': 'Digital Assistant Client',
  },
};

export const pkceSettings = {
  name: 'platform',
  clientId: '',
  endpoints: {
    authorize: `${hostUrl}/oauth2/authorize`,
    token: `${hostUrl}/oauth2/token`,
  },
  scopes: [],
};

const config = {
  apiUrl,
  hostUrl,
  tenantId,
  defaultFetchOpts,
  pkceSettings,
  siteTitle: 'adenin Digital Assistant',
  useTokenAuth: false, // should the SPA use regular session cookie or PKCE oauth flow
  oidcProvider: null, // which (if any) OIDC provider name to use for login
  boardWidthScale: 1, // when != 1 all card widths are multiplied by this scale factor
  boardHeightScale: 1, // when != 1 all card heights are multiplied by this scale factor
  boardDisableBackground: false, // disables the placeholder grid units on board background
  colorScheme: 'auto', // css prefers-color-scheme setting. options: 'auto' (default), 'light', 'dark'
  defaultPath: '/app/assistant/board',
};

if (config.useTokenAuth && pkceSettings.name !== 'platform') {
  defaultFetchOpts.headers['X-Bearer-Type'] = pkceSettings.name;
}

// useful for development PKCE override
if (process.env.USE_PKCE == 'true' && process.env.CLIENT_ID) {
  config.useTokenAuth = true;
  config.pkceSettings.clientId = process.env.CLIENT_ID;
}

export default config;
