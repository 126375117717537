import { useEffect } from 'react';

import config from '../config';

const Index = () => {
  useEffect(() => {
    window.location.href = config.defaultPath;
  });

  return <></>;
};

export default Index;
